import {
  makeStyles,
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  shorthands,
} from '@fluentui/react-components';
import { Info12Regular } from '@fluentui/react-icons';

const tokens = {
  colors: {
    primary: '#174ae4',
    background: '#f0f0f0',
    text: '#000',
  },
  borderRadius: '10px',
  borderWidth: '2px',
};

const useStyles = makeStyles({
  popover: {
    ...shorthands.border(tokens.borderWidth, 'solid', tokens.colors.primary),
    ...shorthands.borderRadius(tokens.borderRadius),
    ...shorthands.padding('1.5px', '15px'),
    ...shorthands.overflow('auto'),
    ...shorthands.margin('2.5px'),
    maxWidth: '264px',
    minWidth: '100px',
    height: 'auto',
    backgroundColor: '#fff',
  },
  title: {
    paddingTop: '4px',
    fontWeight: '700',
    fontSize: '0.8rem',
    width: 'auto',
  },
  content: {
    fontWeight: '400',
    fontSize: '0.8rem',
    paddingBottom: '4px',
  },
  button: {
    justifyContent: 'center',
    minWidth: 'auto',
    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingRight: '0',
  },
  icon: {
    width: '1.2rem',
    height: '1.2rem',
  },
});

export interface IInfoPopoverProps {
  title: string;
  content: string;
}

export const InfoPopover = ({ title, content }: IInfoPopoverProps) => {
  const styles = useStyles();

  return (
    <Popover openOnHover={true} mouseLeaveDelay={0}>
      <PopoverTrigger disableButtonEnhancement>
        <Button
          className={styles.button}
          icon={<Info12Regular className={styles.icon} />}
          aria-label='Information'
        >
        </Button>
      </PopoverTrigger>
      <PopoverSurface tabIndex={-1} className={styles.popover}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
      </PopoverSurface>
    </Popover>
  );
};
