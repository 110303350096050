import { useState, useContext } from 'react';
import {
  Stack,
  IStackStyles,
  Text,
  IStackProps,
  Popup,
  Spinner,
  SpinnerSize,
  TooltipHost,
  List,
} from '@fluentui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';
import { v4 as uuidv4 } from 'uuid';
import { ShowDebugInfoButton, ShowReferenceButton } from './Button';
import {
  isHumanMessage,
  isAiMessage,
  Reference,
  AiMessageDebugInfo,
  CommentData,
  Conversation,
  Message as ApiMessage,
  VersionData,
} from '../utils/api';
import { CommentModal } from './CommentModal';
import { SelectedSkillContext } from '../contexts/SelectedSkillContext';
import {
  Button,
  makeStyles,
  shorthands,
  mergeClasses
} from "@fluentui/react-components";
import { Copy24Regular, Copy24Filled, ThumbLike24Regular, ThumbLike24Filled, ThumbDislike24Regular, ThumbDislike24Filled, Checkmark24Filled } from "@fluentui/react-icons";
import { Icon } from '@fluentui/react/lib/Icon';


const tokens = {
  colors: {
    primary: '#174ae4',
    background: '#f0f0f0',
    text: '#000',
  },
  borderRadius: '6px',
  borderWidth: '3px',
};


const useStyles = makeStyles({
  referenceContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  referenceLabel: {
    minWidth: '80px',
  },
  referenceLabelText: {
    color: tokens.colors.text,
    fontWeight: '700',
    marginTop: '7px',
  },
  referenceList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  referenceItem: {
    ...shorthands.borderRadius(tokens.borderRadius),
    ...shorthands.padding('1.5px', '15px'),
    ...shorthands.overflow('hidden'),
    ...shorthands.margin('2.5px'),
    maxWidth: '20vw',
    color: tokens.colors.text,
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    boxSizing: 'border-box',
    wordBreak: 'break-all',
  },
  referenceItemWithUrl: {
    ...shorthands.border(tokens.borderWidth, 'solid', tokens.colors.primary),
    backgroundColor: '#fff',
  },
  referenceItemWithoutUrl: {
    ...shorthands.border(tokens.borderWidth, 'solid', tokens.colors.background),
    backgroundColor: tokens.colors.background,
  },
  referenceLink: {
    ...shorthands.textDecoration('none'),
    color: tokens.colors.text,
  },
});

interface IndexMapping {
  [originalIndex: number]: number;
}
interface RemoveDuplicatesResult {
  uniqueReferences: Reference[];
  indexMapping: IndexMapping;
}

export function getRelatedDocuments(
  citedReferences: Reference[],
  dedupedFullReferences: Reference[]
): Reference[] {
  const setReference = new Set(citedReferences);
  return dedupedFullReferences.filter((element) => !setReference.has(element));
}

function removeDuplicates(references: Reference[]): RemoveDuplicatesResult {
  const uniqueIds = new Map<string | Number, number>();
  const uniqueReferences: Reference[] = [];
  const indexMapping: IndexMapping = {};

  for (let i = 0; i < references.length; i++) {
    const reference = references[i];
    if (!uniqueIds.has(reference.id)) {
      uniqueIds.set(reference.id, uniqueReferences.length);
      uniqueReferences.push(reference);
    }
    indexMapping[i] = uniqueIds.get(reference.id)!;
  }

  return { uniqueReferences, indexMapping };
}

export function makeRefSuperscript(references: Reference[], msg: string): [Reference[], string] {
  // Replace "[docX](...)" with "[^X^](...)"
  msg = msg.replaceAll(/\[doc(\d+)\](?=\()/g, '[^$1^]');
  // Replace "[docX]" with "^X^"
  msg = msg.replaceAll(/\[doc(\d+)\]/g, '^$1^');
  return [references, msg];
}

export function dedupRefandRemapRefNum(dedup_ref: Reference[], remap_msg: string): [Reference[], string] {

  //dedup ref
  let result: RemoveDuplicatesResult = removeDuplicates(dedup_ref);
  let new_ref = result.uniqueReferences;

  //remap ref
  let new_str = remap_msg;
  for (let idx = 0; idx < Object.keys(result.indexMapping).length; idx++) {
    let tarStr = '^' + (idx + 1).toString() + '^';
    let desStr = '^' + (result.indexMapping[idx] + 1).toString() + '^';
    new_str = new_str.replaceAll(tarStr, desStr);
  }

  //remove consecutive and identical ref number
  new_str = new_str.replaceAll(/\^(\d+)\^(\^\1\^)+/g, '^$1^');

  return [new_ref, new_str];
}

export function removeNoUseRef(oldReferences: Reference[], inputString: string): [Reference[], string] {
  const regex = /\^(\d+)\^/g;
  let match;
  const matches: string[] = [];

  while ((match = regex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  if (matches.length === 0) {
    const newReferences: Reference[] = [];
    return [newReferences, inputString];
  }

  // Get unique reference numbers mentioned in the answer
  // ['3', '4', '1', '4', '3'] -> [3, 4, 1]
  const uniqueNumericMatches = Array.from(new Set(matches), Number);
  // The order of newReferences will be the same as the order of a reference's first appearance in the answer
  // If the answer mentioned ^3^, ^4^, ^1^, the order of newReferences will be [ref3, ref4, ref1]
  const newReferences: Reference[] = [];
  // Final answer string
  let updatedString = inputString;

  uniqueNumericMatches.forEach(refNum => {
    const oldRefPattern = new RegExp(`\\^${refNum}\\^`, "g");
    const oldRef = oldReferences[refNum - 1];

    if (oldRef) {
      newReferences.push(oldRef)
      // newRef will become #^X#^ at this moment instead of ^X^ because X could appear later in uniqueNumericMatches
      // and if we don't use #^X#^, we will replace ^X^ with the wrong reference
      const newRef = `#^${newReferences.length}#^`;
      updatedString = updatedString.replace(oldRefPattern, newRef);
    } else {
      // In case the answer mentioned a reference that doesn't exist in the references list
      updatedString = updatedString.replace(oldRefPattern, '');
    }
  });
  // Replace all "^#" with "^" so "#^X#^" will become "^X^"
  updatedString = updatedString.replace(/#\^/g, '^');

  return [newReferences, updatedString];
}

export const UserIcon = () => (
  <Icon
    iconName="Contact"
    styles={{
      root: {
        borderRadius: '50%',
        fontSize: '1.2rem',
        padding: '2px',
        backgroundColor: 'gray',
        color: 'white',
        textAlign: 'center',
        verticalAlign: 'center',
      },
    }}
  />
);
export const Message = ({
  msgObj,
  errorDetail,
  stackProps,
  referenceIncludeId = true,
  corrQuestion,
  msgStatus,
  onShowDebugInfo,
  msgStatusLoading,
  userName,
  versionData,
}: IMessageProps) => {
  let msgContent: string = 'Default Message. You should not see this!';
  let references: Reference[] = [];
  let relatedDocuments: Reference[] = [];
  let dedupedFullReferences: Reference[] = [];
  let debug_info: AiMessageDebugInfo | null = null;
  let is_ai_response = false;
  let answer: string;
  let answerId: string;
  let dedup_ref: boolean = true;
  let remove_nouse_ref: boolean = true;

  const styles = useStyles();
  const selectedSkill = useContext(SelectedSkillContext);
  const [showRefs, setShowRefs] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [commentData, setCommentData] = useState<CommentData>({
    id: '',
    user_id: '',
    answer_id: '',
    comment: '',
    question: '',
    answer: '',
    version: '',
    is_like: true,
    dislike_reason: '',
    references: [],
    relatedDocuments: [],
    conversation: {} as Conversation,
    human_reachout: false,
    selected_skill: '',
  });
  const [likedStatus, setLikedStatus] = useState<boolean | null>(null);

  if (errorDetail) {
    msgContent = errorDetail;
  } else if (msgObj) {
    if (isHumanMessage(msgObj)) {
      is_ai_response = false;
      msgContent = msgObj.content;
    } else if (isAiMessage(msgObj)) {
      is_ai_response = true;
      msgContent = msgObj.content;
      answer = msgContent;
      answerId = msgObj.id === undefined ? '' : msgObj.id;
      if (msgObj.references) {
        references = msgObj.references;
      }
      [references, msgContent] = makeRefSuperscript(references, msgContent);

      if (dedup_ref && references && references.length > 0) {
        [references, msgContent] = dedupRefandRemapRefNum(references, msgContent);
        dedupedFullReferences = references
      }
      if (remove_nouse_ref && references && references.length > 0) {
        [references, msgContent] = removeNoUseRef(references, msgContent);
      }
      if (msgObj.debug_info) {
        debug_info = msgObj.debug_info;
      }
      relatedDocuments = getRelatedDocuments(references, dedupedFullReferences)
    } else {
      msgContent = `UNKNOWN MESSAGE TYPE: ${JSON.stringify(msgObj)}`;
    }
  } else {
    console.log('ERROR: No msg obj pass to Message');
  }

  const msgStyles: Partial<IStackStyles> = {
    root: {
      wordBreak: 'normal',
      overflowWrap: 'anywhere',
      color: '#000',
      fontSize: '1.125rem',
    },
  };
  const msgAIStyles: Partial<IStackStyles> = {
    root: {
      wordBreak: 'normal',
      overflowWrap: 'anywhere',
      color: '#000',
      fontSize: '1.125rem',
      marginTop: '2rem',
      marginBottom: '2rem'
    },
  };

  const renderIcon = () => {
    if (is_ai_response) {
      return (
        <Stack style={{ display: 'block' }}>
          <Text
            styles={{
              root: {
                color: '#000',
                fontSize: '1rem',
                fontStyle: 'normal',
                fontWeight: '700',
              },
            }}
          >
            AI Assistant
          </Text>
        </Stack>
      );
    } else {
      return (
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: '0.56rem' }}
        >
          <UserIcon />
          <Stack>
            <Text
              styles={{
                root: {
                  color: '#000000',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: '700',
                },
              }}
            >
              {' '}
              {userName}
            </Text>
          </Stack>
        </Stack>
      );
    }
  };

  const renderContent = () => {
    return (
      <div data-testid="message-content">
        <ReactMarkdown
          linkTarget="_blank"
          remarkPlugins={[remarkGfm, supersub]}
          children={msgContent}
        />
      </div>
    );
  };

  const renderReferences = () => {
    return (
      <Stack className={styles.referenceContainer}>
        <Stack className={styles.referenceLabel}>
          <h4 className={styles.referenceLabelText}>References</h4>
        </Stack>
        <Stack className={styles.referenceList} data-testid='reference-list'>
          {references.map((refObj, idx) => {
            const isInternalDoc = refObj.url === null || refObj.url === '';
            const linkText = formatReferenceText(
              idx,
              refObj.title,
              isInternalDoc
            );
            return (
              <TooltipHost content={linkText} key={idx}>
                <Text
                  className={mergeClasses(
                    styles.referenceItem,
                    isInternalDoc
                      ? styles.referenceItemWithoutUrl
                      : styles.referenceItemWithUrl
                  )}
                >
                  <a
                    className={styles.referenceLink}
                    data-testid={'reference-item-' + idx}
                    target="_blank"
                    rel="noreferrer"
                    href={refObj.url ? refObj.url : undefined}
                  >
                    {linkText}
                  </a>
                </Text>
              </TooltipHost>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  const renderRelatedDocuments = () => {
    // We should NOT use reference's ID as the key here, because the same doc can be referenced multiple times.
    // And it's ok to use array index as the key because the items in reference list is fixed.
    if (relatedDocuments && relatedDocuments.length > 0) {
      return (
        <Stack horizontalAlign="start">
          <List
            data-testid='relatedDocuments-list'
            items={relatedDocuments}
            onRenderCell={(item) => {
              if (!item) return null;

              let linkText: string;
              if (referenceIncludeId === true) {
                linkText = `[#${item.id?.toString() ?? ''}] ${item.title ?? ''}`;
              } else {
                linkText = item.title ?? '';
              }
              if (item.url === null || item.url === '') {
                linkText = `[MS Internal Document] ${linkText}`;
              }
              return (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', paddingLeft: '1.6rem' }}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.url ?? undefined}
                  >
                    {linkText}
                  </a>
                </div>
              );
            }}
          />
        </Stack>
      );
    }
    return null;
  };

  const renderMessageFooter = () => {
    return (
      <Stack.Item align="stretch">
        {references.length > 0 && renderReferences()}
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { height: '2.4rem' } }}>
          {relatedDocuments.length > 0 ? (
            <ShowReferenceButton
              number={relatedDocuments.length}
              onToggle={setShowRefs}
            />
          ) : (
            <div className="placeholder" />
          )}
        </Stack>
      </Stack.Item>
    );
  };

  const formatReferenceText = (index: number, title: string, isInternalDoc: boolean): string => {
    const referenceNumber = `${index + 1}.`;
    const internalDocPrefix = isInternalDoc ? '[MS Internal Document]' : '';
    return `${referenceNumber} ${internalDocPrefix} ${title}`.trim();
  };

  const renderCommentFooter = () => {
    return (
      is_ai_response && (
        <Stack horizontal horizontalAlign="start" style={{ marginBottom: '2rem' }}>
          {renderDebugInfoButton() || <div className="placeholder" />}
          {renderComment()}
        </Stack>
      )
    )
  }

  const renderDebugInfoButton = () => {
    if (debug_info && onShowDebugInfo) {
      const info: AiMessageDebugInfo = debug_info;
      return <ShowDebugInfoButton onClick={() => onShowDebugInfo(info)} />;
    }
  };

  const [likeIcon, setLikeIcon] = useState(false);
  const [dislikeIcon, setDislikeIcon] = useState(false);
  const [copyIcon, setCopyIcon] = useState(false);
  const [showCheckIcon, setCheckIcon] = useState(false);
  const renderComment = () => {
    return (
      <Stack horizontal
        styles={{
          root: {
            position: 'relative',
            marginTop: '0.5rem'
          }
        }} >
        <TooltipHost content="Like the response">
          <Button size="small"
            id="like-btn"
            data-testid="like-btn"
            aria-label="Like"
            icon={likedStatus === true ? <ThumbLike24Filled aria-label="CheckIconFill" /> : (likeIcon ? <ThumbLike24Filled aria-label="CheckIconFill" /> : <ThumbLike24Regular aria-label="CheckIconRegular" />)}
            onClick={() => commentAction(true)}
            onMouseEnter={() => mouseEnt('L')}
            onMouseLeave={() => mouseLev('L')}
            style={{ pointerEvents: likedStatus === null ? 'auto' : 'none' }}
          />
        </TooltipHost>
        <TooltipHost content="This is a bad response">
          <Button size="small"
            id="dislike-btn"
            data-testid="dislike-btn"
            aria-label="Dislike"
            icon={likedStatus === false ? <ThumbDislike24Filled /> : (dislikeIcon ? <ThumbDislike24Filled /> : <ThumbDislike24Regular />)}
            onClick={() => commentAction(false)}
            onMouseEnter={() => mouseEnt('D')}
            onMouseLeave={() => mouseLev('D')}
            style={{ pointerEvents: likedStatus === null ? 'auto' : 'none' }}
          />
        </TooltipHost>
        {showCheckIcon ?
          <Button size="small"
            data-testid="check-btn"
            aria-label="Content Copied"
            aria-live="assertive"
            icon={<Checkmark24Filled />}
          /> :
          <TooltipHost content="Copy the response">
            <Button size="small"
              id="copy-btn"
              data-testid="copy-btn"
              aria-label="Copy"              
              icon={copyIcon ? <Copy24Filled /> : <Copy24Regular />}
              onClick={() => copyAction()}
              onMouseEnter={() => mouseEnt('C')}
              onMouseLeave={() => mouseLev('C')}
            />
          </TooltipHost>
          
        }

      </Stack>
    );
  };

  const mouseEnt = (type: String) => {
    if (type === 'L') {
      setLikeIcon(true);
    }
    if (type === 'D') {
      setDislikeIcon(true);
    }
    if (type === 'C') {
      setCopyIcon(true);
    }
  };

  const mouseLev = (type: String) => {
    if (type === 'L') {
      setLikeIcon(false);
    }
    if (type === 'D') {
      setDislikeIcon(false);
    }
    if (type === 'C') {
      setCopyIcon(false);
    }
  };

  const commentAction = async (likeState: boolean) => {
    let commentData: CommentData = {
      id: uuidv4().toString(),
      user_id: '',
      answer_id: answerId,
      comment: '',
      question: corrQuestion,
      answer: answer,
      version: versionData?.version || '',
      is_like: likeState,
      dislike_reason: '',
      references: references,
      relatedDocuments: relatedDocuments,
      conversation: {} as Conversation,
      human_reachout: false,
      selected_skill: selectedSkill.id,
    };
    if (likedStatus === null) {
      setCommentData(commentData);
      setShowComment(true);
    }
  };

  const copyAction = () => {
    const confidentialWarning = '# Microsoft Confidential Information shared under NDA'
    const aiWarning = '# This is an AI-generated response. Please verify the information before taking any action.'
    navigator.clipboard.writeText(confidentialWarning + '\n' + aiWarning + '\n\n' + msgContent).then(
      () => {
        setCheckIcon(true)
        document.getElementById('copy-btn')?.setAttribute('aria-label', 'Copied');
        document.getElementById('copy-btn')?.focus();
        setTimeout(() => {
          mouseLev('C')
          setCheckIcon(false)
        }, 2000);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  }
  const closeModal = (is_like: boolean) => {
    // back to button and focus
    if (is_like) {
      document.getElementById('like-btn')?.focus();
    } else {
      document.getElementById('dislike-btn')?.focus();
    }
    setShowComment(false)
  }

  return (
    <Stack>
      {(msgObj?.role === 'assistant' && msgStatusLoading) && (
        <Stack horizontal verticalAlign='center' styles={{ root: { padding: '0.3rem', height: '40px' } }}>
          <Text
            styles={{
              root: {
                fontSize: '1rem',
                fontStyle: 'normal',
                fontWeight: '500',
                marginRight: '0.5rem',
              }
            }}
          >
            {msgStatus}
          </Text>
          {msgStatusLoading && <Spinner size={SpinnerSize.small} />}
        </Stack>
      )}
      {msgObj?.content && (
        <Stack
          // horizontalAlign="start"
          styles={is_ai_response ? msgAIStyles : msgStyles}
          className="noP"
          {...stackProps}
        >
          {renderIcon()}
          {renderContent()}
          {is_ai_response && renderMessageFooter()}
          {showRefs && <Popup>{renderRelatedDocuments()}</Popup>}
          {renderCommentFooter()}
          <CommentModal
            isOpen={showComment}
            commentData={commentData}
            onCloseComment={() => closeModal(commentData.is_like)}
            onCommentSubmitted={() => setLikedStatus(commentData.is_like)}
          />
        </Stack>
      )}
    </Stack>
  );
};
export interface IMessageProps {
  msgObj?: ApiMessage;
  errorDetail?: string;
  stackProps?: IStackProps;
  referenceIncludeId?: boolean;
  corrQuestion?: string;
  msgStatus?: string;
  msgStatusLoading?: boolean;
  userName?: string;
  onShowDebugInfo?: (debugInfo: AiMessageDebugInfo) => void;
  versionData: VersionData;
}
