import { useEffect, useRef, useState } from 'react';
import { Stack, TextField, ITextFieldProps, Text } from '@fluentui/react';
import { SendButton } from './Button';
import { FluentProvider, webLightTheme, makeStyles, Button } from "@fluentui/react-components";
import { INPUT_LIMIT } from '../constants/env';

const useStyles = makeStyles({
  newTopicBtnSmall: {
    backgroundColor: '#FAFAFA',
    width: "100%",
    position: "absolute",
    top: "0",
    right: "4rem",
  },
  newTopicBtnLarge: {
    backgroundColor: '#FAFAFA',
    width: "100%",
    position: "absolute",
    top: "0",
    right: "9rem",
  },
  button: {
    backgroundColor: '#174AE4',
    width: '8rem',
    height: '3.125rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    fontSize: '1rem',
    marginRight: '2rem',
  },
})

export interface IMaxHeightTextFieldProps extends ITextFieldProps {
  maxHeight: number;
};

export const MaxHeightTextField = ({ maxHeight, value, ...rest }: IMaxHeightTextFieldProps) => {
  const textFieldRef = useRef<HTMLDivElement>(null);
  const [textFieldStyles, setTextFieldStyles] = useState({
    root: {
      width: '100%',
      maxHeight: '30vh',
      marginBottom: '0.5rem',
    },
    fieldGroup: {
      borderRadius: '10px',
      minHeight: 0,
      borderBottom: '1.5px solid #BDBDBD',
      borderLeft: '1px solid #BDBDBD',
      borderRight: '1px solid #BDBDBD',
      borderTop: '1px solid #BDBDBD',
      padding: '2.5px'
    },
    field: {
      backgroundColor: '#fff',
      fontSize: '1rem',
      paddingTop: '1rem',
      marginBottom: '25px'
    },
  });

  const handleStyle = (type: string) => {
    setTextFieldStyles(prevStyles => ({
      ...prevStyles,
      fieldGroup: {
        ...prevStyles.fieldGroup,
        borderBottom: type === 'F' ? '1.5px solid #464FEB' : '1.5px solid #BDBDBD',
      },
    }));
  };

  useEffect(() => {
    if (textFieldRef.current) {
      const textarea = textFieldRef.current.querySelector('textarea');
      if (!textarea) return;

      // First adjust the height to fit the content to get correct scrollHeight.
      textarea.style.height = '55px';

      // Then adjust the height to the scrollHeight + top and bottom border width, but constrain it to the maxHeight
      const style = window.getComputedStyle(textarea);
      const totalScrollHeight = textarea.scrollHeight + parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
      textarea.style.height = totalScrollHeight <= maxHeight ? `${totalScrollHeight}px` : `${maxHeight}px`;
      textarea.style.overflowY = totalScrollHeight <= maxHeight ? 'hidden' : 'visible';
    }
  }, [value, maxHeight]);

  return (
    <>
      <TextField
        elementRef={textFieldRef}
        rows={1}
        onFocus={() => handleStyle('F')}
        onBlur={() => handleStyle('B')}
        styles={textFieldStyles}
        value={value}
        {...rest}
        borderless
      />
    </>
  );
};

export interface IUserInputProps {
  scenario?: 'wait_select_skill' | 'skill_selected' | 'conversation';
  onInputSubmit?: (inputText: string) => void;
  onInputClear?: () => void;
  waiting?: boolean;
}

export const UserInput = ({ onInputSubmit, waiting, onInputClear, scenario }: IUserInputProps) => {
  const styles = useStyles();
  const [inputText, setInputText] = useState('');

  const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    setInputText(newValue || '');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      if (inputText.length <= INPUT_LIMIT) {
        handleSubmit();
      }
      event.preventDefault();
    }
  };

  const handleSubmit = async () => {
    if (inputText.trim() === "") {
      return;
    }

    setInputText('');
    if (onInputSubmit) {
      onInputSubmit(inputText);
    }
  };

  return (
    <Stack
      id="input-area"
      styles={{
        root: {
          width: '100%',
          borderRadius: '0.5rem',
          overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '1px',
          paddingLeft: '15%',
          paddingRight: '15%',
          "@media (max-width: 576px)": {
            paddingLeft: '15%',
            paddingRight: '5%',
          },
        },
      }}
    >
      {/* This Stack (div) has position: relative that help positioning the button which has position: absolute*/}
      <Stack styles={{root: {position: 'relative', width: '100%', height: 'auto'}}}>
        <FluentProvider theme={webLightTheme} className={styles.newTopicBtnLarge}>
          <Button
            data-testid='clear-btn'
            className={styles.button}
            appearance="primary"
            shape="circular"
            size="large"
            onClick={() => onInputClear && onInputClear()}
            onKeyDown={(event) => {
              if (event.shiftKey || event.key === 'Tab') {
                // It's tab or shift-tab, ignore!
                return;
              }
              onInputClear && onInputClear()
            }}
            aria-label='New Topic'
          >
            New Topic
          </Button>
        </FluentProvider>
        <MaxHeightTextField
          maxHeight={80}
          multiline
          resizable={false}
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          readOnly={waiting}
          borderless
          placeholder="Write your question here..."
          errorMessage={inputText.length > INPUT_LIMIT ? `The total length must not exceed ${INPUT_LIMIT} characters` : undefined}
        />
        <Stack horizontal styles={{ root: { width: '100%', position: 'relative', bottom: inputText.length > INPUT_LIMIT ? '55px' : '35px' } }}>
          <Stack styles={{ root: { paddingLeft: '10px', paddingTop: '2.5px', color: '#707070', width: '50%' } }}>
            <Text styles={{ root: { fontSize: '12px', color: '#747474' } }}  aria-describedby="charLimit">{inputText.length}/{INPUT_LIMIT}</Text>
          </Stack>

          <SendButton
            data-testid="prompt-submit-btn"
            onClick={handleSubmit}
            disabled={inputText.trim() === '' || inputText.length > INPUT_LIMIT || waiting}
            aria-label='Send'
          />
        </Stack>
        <Stack id="charLimit" aria-live="polite" aria-atomic="true" style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          whiteSpace: 'nowrap',
          border: '0',
        }}>
          {inputText.length}characters used, {2000 - inputText.length}remaining</Stack>
      </Stack>
    </Stack>
  );
}
