import { Configuration, PopupRequest, LogLevel } from "@azure/msal-browser-1p";
import { MSAL_AUTHORITY, MSAL_REDIRECT_URI, MSAL_CLIENT_ID, MSAL_SCOPES } from "./constants/env";

export const msalConfig: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID, 
    authority: MSAL_AUTHORITY,
    redirectUri: MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    return;
            }
        }
    }
}
};

export const msaLoginRequest: PopupRequest = {
  scopes: MSAL_SCOPES, // "SCOPES_NEEDED"
  prompt: "select_account" // Allow user to select personal or work account
};
