    
import { Stack, Text } from '@fluentui/react';
import { ReactComponent as MsLogo } from '../assets/ms_logo.svg';

interface MsHeaderProps {
    children?: React.ReactNode;
  }

export const MsHeader: React.FC<MsHeaderProps> = ({ children }) => {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={{
        root: {
          width: '100%', marginTop: '1.5rem', minHeight: "3rem",
          "@media (max-width:320px)": {
            display: 'block',
          }
        }
      }}
    >
      <Stack
        id="msft-logo-and-preview"
        horizontal
        verticalAlign="center"
        horizontalAlign="start"
        tokens={{ childrenGap: '0.81rem' }}
        styles={{ root: { marginLeft: '2.06rem' } }}
      >
        <Stack id="msft-logo">
          <MsLogo style={{ width: '7.25rem', height: 'auto' }} aria-label='Microsoft logo' role="img" />
        </Stack>
        <Stack
          id="preview"
          verticalAlign="center"
          horizontalAlign="center"
          styles={{
            root: {
              width: '6.75rem',
              height: '1.75rem',
              backgroundColor: 'rgba(217, 217, 217, 0.22)',
              borderRadius: '1.125rem',
            },
          }}
        >
          <Text styles={{ root: { fontSize: '1.125rem' } }}>Preview</Text>
        </Stack>
      </Stack>
      {children}
    </Stack>        
  );
}
