import { Stack, Text } from '@fluentui/react';
import { SkillData } from '../utils/api';
import { ReactComponent as ServerSkillLogo } from '../assets/windows_server_skill.svg';
import { ReactComponent as IoTSkillLogo } from '../assets/windows_iot_skill.svg';
import useSize from '../utils/useSize';
import { ReactElement } from 'react';

export const SkillContainer = ({
  isNormalSize,
  isSelect,
  skillData,
}: {
  isNormalSize: boolean;
  isSelect: boolean;
  skillData: SkillData;
}) => {
  const windowsize = useSize();

  const normalSizeStyle = {
    root: {
      padding: '1.38rem',
      paddingBottom: windowsize[1] > 710 ? '3.34rem' : '1.38rem',
      marginRight: '1.8rem',
      marginLeft: '1.8rem',
      width: 'calc(100% - 3.69rem);',
      backgroundColor: 'white',
      borderRadius: '0.9375rem',
      border: isSelect ? '3px solid #174AE4' : '',
      boxShadow:
        '0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11), 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13)',
      maxWidth: '580px',
      minWidth: '320px',
      minHeight: windowsize[1] > 710 ? '172px' : '135px',
      cursor: 'pointer',
      '&:hover': {
        background: '#F6F6F6',
      },
    },
  };

  const smallSizeStyle = {
    root: {
      paddingLeft: '1rem',
      paddingRight: '1.5rem',
      marginLeft: '1.5rem',
      backgroundColor: 'white',
      borderRadius: '0.9375rem',
      border: isSelect ? '3px solid #174AE4' : '3px solid #ffffff00',
      boxShadow:
        '0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11), 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13)',
      minWidth: '150px',
      cursor: 'pointer',
      '&:hover': {
        background: '#F6F6F6',
      },
      '@media (max-width: 576px)': {
        marginRight: '0.5rem',
        whiteSpace: 'nowrap',
      },
    },
  };

  let skillLogo: ReactElement;
  if (skillData.id === 'windows_server_sme_skill') {
    skillLogo = (
      <ServerSkillLogo
        style={{
          width: '2.5rem',
          height: 'auto',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      />
    );
  } else if (skillData.id === 'windows_iot_sme_skill') {
    skillLogo = (
      <IoTSkillLogo
        style={{
          width: '2.5rem',
          height: 'auto',
          paddingTop: '0.7rem',
          paddingBottom: '0.3rem',
        }}
      />
    );
  } else {
    // Default logo
    skillLogo = <ServerSkillLogo />;
  }

  return (
    <Stack
      id="left-panel-skill-list"
      data-testid={skillData.display_name + ' Skill Container'}
      data-skillselected={isSelect?"true":"false"}
      styles={isNormalSize ? normalSizeStyle : smallSizeStyle}
      tabIndex={0}
      role='button'
      aria-label={skillData.display_name}
    >
      <Stack id="skill-list" verticalAlign="start" horizontalAlign="start">
        <Stack>
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: '0.88rem' }}            
          >
            {skillLogo}
            <Text
              styles={{
                root: {
                  fontSize: '1.25rem',
                  color: '#174AE4',
                  fontWeight: 600,
                  lineHeight: '1.75rem',
                },
              }}
            >
              {skillData.display_name}
            </Text>
          </Stack>
          {isNormalSize && (
            <Text block>
              <Text variant="medium" styles={{ root: { fontWeight: 'bold', fontSize: '1.25rem' } }}>
                Knowledge Capabilities:{' '}
              </Text>
              <Text styles={{ root: { fontSize: '1.25rem' } }}>
                {skillData.description}
              </Text>
            </Text>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
