import { Stack, Text, Link } from '@fluentui/react';
import { FAQ } from './FAQ';
import { Terms } from './Terms';
import { useState } from 'react';
import { FEEDBACK_URL, PRIVACY_URL } from '../constants/env';

export const Footer = () => {
  const [activeModal, setActiveModal] = useState<'terms' | 'faq' | null>(null);
  const handleTermsClick = () => setActiveModal('terms');
  const handleFAQClick = () => setActiveModal('faq');
  const handleOnClose = () => setActiveModal(null);

  return (
    <footer>
      <Stack>
        <Text styles={{ root: { fontSize: '1.25rem', textAlign: 'center', color: '#0072c9' } }}>
          <Link target="_blank" href={FEEDBACK_URL} styles={{ root: { color: '#0072c9' } }}>Feedback</Link> |{' '}
          <Link onClick={() => { if (handleTermsClick !== undefined) { handleTermsClick() } }} styles={{ root: { color: '#0072c9' } }}>Terms</Link> |{' '}
          <Link target="_blank" href={PRIVACY_URL} styles={{ root: { color: '#0072c9' } }}>Privacy</Link> |{' '}
          <Link onClick={() => { if (handleFAQClick !== undefined) { handleFAQClick() } }} styles={{ root: { color: '#0072c9' } }}>Responsible AI FAQ</Link>
        </Text>
        <Terms open={activeModal === 'terms'} onClose={handleOnClose} />
        <FAQ open={activeModal === 'faq'} onClose={handleOnClose} />
      </Stack>
    </footer>
  );
};